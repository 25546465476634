// 登录接口
import {
	axios
} from '@/utils/http.js'

const CompanyApi = {
	CompanyTree: '/v3/Company/Tree',//列表树
	CompanyDel: '/v3/Company/Del',//删除公司
	CompanyEdit: '/v3/Company/Edit',//编辑公司
	CompanyAdd: '/v3/Company/Add',//添加公司
	CompanyEntry: '/v3/Company/Entry',//进入公司
	dataTwin: '/v3/Company/DigitalTwin',//数据孪生
	dataPay: '/v3/Company/Pay',//充值
	dataWarning: '/v3/Company/Warning',//充值
	dataCharging: '/v3/Company/Charging',//充值
	
}
//充值
export function dataCharging(data) {
	return axios({
		url: CompanyApi.dataCharging,
		method: 'post',
		data:data
	})
}
//充值
export function dataWarning(data) {
	return axios({
		url: CompanyApi.dataWarning,
		method: 'post',
		data:data
	})
}
//充值
export function dataPay(data) {
	return axios({
		url: CompanyApi.dataPay,
		method: 'post',
		data:data
	})
}
//数据孪生
export function dataTwin(data) {
	return axios({
		url: CompanyApi.dataTwin,
		method: 'post',
		data:data
	})
}
//进入公司
export function CompanyEntry(data) {
	return axios({
		url: CompanyApi.CompanyEntry,
		method: 'post',
		data:data
	})
}
//添加公司
export function CompanyAdd(data) {
	return axios({
		url: CompanyApi.CompanyAdd,
		method: 'post',
		data:data
	})
}
//编辑公司
export function CompanyEdit(data) {
	return axios({
		url: CompanyApi.CompanyEdit,
		method: 'post',
		data:data
	})
}
//删除公司
export function CompanyDel(data) {
	return axios({
		url: CompanyApi.CompanyDel,
		method: 'post',
		data:data
	})
}
//列表
export function CompanyTree(data) {
	return axios({
		url: CompanyApi.CompanyTree,
		method: 'post',
		data:data
	})
}
